<template>
  <div class="productDetail-components">
    <div class="wrap bc-white ">
      <div class="p-60">
        <el-row :gutter="60">
          <el-col :span="10">
            <el-carousel indicator-position="none" class="banner">
              <el-carousel-item
                class="banner-item"
                v-for="(item, index) in bannerImgs"
                :key="index"
              >
                <img
                  :src="item.pic"
                  width="100%"
                  height="100%"
                  :alt="item.title"
                  :title="item.title"
                />
              </el-carousel-item>
            </el-carousel>
          </el-col>
          <el-col :span="10" v-if="!loading" v-loading="loading">
            <h3 class="fz-24 p-t-40">{{ info.title }}</h3>
            <p class="p-t-20 color-6" style="line-height: 1.5">{{ info.feature }}</p>
            <p></p>
            <p class="p-t-20 color-6">{{ info.shop }}</p>
            <p class="m-t-30">
              零售价<span class="color-blue"
                >￥<span class="fz-24">{{ info.price }}</span></span
              >
            </p>

            <div class="m-t-80 ">
              <!-- <a
                v-if="info.go_link"
                style="display: inline-block;margin-bottom: 20px"
                :href="info.go_link"
                target="_blank"
              > -->
              <el-button class=" btn" type="primary" @click="showQrCodeModal">去购买</el-button>
              <!-- </a> -->
              <!-- <a
                v-if="info.buy_link"
                style="display: inline-block;margin-left: 20px;margin-bottom: 20px"
                :href="info.buy_link"
                target="_blank"
              >
                <el-button class="btn" type="primary" >订购申请</el-button>
              </a> -->
              <!-- <a v-if="info.prototype_link" style="display: inline-block;margin-left: 20px;margin-bottom: 20px"  :href="info.buy_link" target="_blank">
                                <el-button  class=" btn" type="success">样机申请</el-button>
                            </a> -->
            </div>
          </el-col>
          <el-col :span="4" class="info-right">
            <div class="content">
              <div class="header">商品客服联系方式</div>
              <div class="info">
                <div class="product-info">
                  <div class="product-info-item">邮箱： muding92@163.com</div>
                  <div class="product-info-item">微信： dxm_dd</div>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>

      <div class="floor2">
        <div class="nav" v-if="isShowB">
          <a
            href="javascript:void 0;"
            v-if="isShowA"
            :class="{ active: showType }"
            @click="showType = 1"
            >产品详情</a
          >
          <a
            href="javascript:void 0;"
            class="m-l-100"
            :class="{ active: !showType }"
            @click="showType = 0"
            >规格参数</a
          >
        </div>
        <div class="p-t-45 p-b-45">
          <div v-if="showType">
            <div v-for="(item, index) in productInfoA" :key="index">
              <img width="100%" :src="item.pic" alt="" />
            </div>
          </div>
          <div v-if="!showType">
            <div v-for="(item, index) in productInfoB" :key="index">
              <img width="100%" :src="item.pic" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="height:50px"></div>
    <el-dialog
      title="扫码支付"
      :visible.sync="qrCodeModalVisible"
      width="300px"
      @close="closeDialog"
    >
      <img width="100%" :src="info.qrcode" alt="" />
    </el-dialog>
  </div>
</template>

<script>
import { getInfo } from '@/api/product'
export default {
  name: 'productDetail',
  data() {
    return {
      loading: false,
      isShowA: false,
      isShowB: false,
      activeName: 'a',
      info: {
        title: '',
        feature: '',
        price: '',
        qrcode: ''
      },
      bannerImgs: [],
      productInfoA: [],
      productInfoB: [],

      showType: 1,
      qrCodeModalVisible: false
    }
  },
  created: function() {
    console.log('created')
    this.getInfo()
  },
  methods: {
    getInfo() {
      console.log('getInfo')
      let id = this.$route.params.id
      this.loading = true
      getInfo({ id }).then(res => {
        this.info = res.data

        res.data.attachments.forEach(item => {
          if (item.category == 1) {
            this.bannerImgs.push(item)
          } else if (item.category == 2) {
            this.isShowA = true

            this.productInfoA.push(item)
          } else if (item.category == 3) {
            this.isShowB = true
            this.productInfoB.push(item)
          }
        })
        this.loading = false
      })
    },
    showQrCodeModal() {
      this.qrCodeModalVisible = true
    },
    closeDialog() {
      this.qrCodeModalVisible = false
    }
  }
}
</script>

<style scoped lang="less">
.banner {
  width: 470px;
  height: 470px;
  .banner-item {
    width: 470px;
    height: 470px;
  }
}
.btn {
  width: 210px;
  height: 54px;
  border-radius: 0;
  font-size: 18px;
}
.floor2 {
  border-top: 1px solid #d8d8d8;
  padding: 20px 220px;
  .nav {
    text-align: center;
    a {
      display: inline-block;
      color: #666;
      padding: 0 5px 20px;
    }
    a.active,
    a:hover {
      color: #1ea7f4;
      border-bottom: 2px solid #1ea7f4;
    }
  }
}
.info-right {
  display: flex;
  flex-direction: column;
  max-width: 220px;
  // margin-left: 30px;
  border-left: 1px solid #ebebeb;
  padding: 0 !important;
  .content {
    height: 100%;
    border: 1px solid #ebebeb;
    .product-info {
      padding: 15px 0;
      margin: 0 20px;
      .product-info-item {
        width: 152px;
        margin-bottom: 5px;
        overflow: hidden;
        color: #333;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 12px;
      }
    }
  }
  .header {
    height: 32px;
    padding-left: 20px;
    font-size: 14px;
    line-height: 32px;
    color: #333;
    background: #f8f8f8;
    border-bottom: 1px solid #ebebeb;
  }
}
</style>
